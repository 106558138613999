/**
* Miscellaneous styles
*/

// gsap
.gsapTest {
  width: 100px;
  height: 100px;
  background-color: red;
}
