/**
* MAIN STYLESHEET
*/
@import url(../../../node_modules/normalize.css/normalize.css);
body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms; }

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0s; }
  body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
    transition-delay: 50ms; }

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms; }

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0s; }
  body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
    transition-delay: 100ms; }

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms; }

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0s; }
  body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
    transition-delay: 150ms; }

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms; }

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0s; }
  body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
    transition-delay: 200ms; }

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms; }

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0s; }
  body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
    transition-delay: 250ms; }

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms; }

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0s; }
  body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
    transition-delay: 300ms; }

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms; }

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0s; }
  body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
    transition-delay: 350ms; }

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms; }

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0s; }
  body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
    transition-delay: 400ms; }

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms; }

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0s; }
  body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
    transition-delay: 450ms; }

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms; }

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0s; }
  body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
    transition-delay: 500ms; }

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms; }

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0s; }
  body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
    transition-delay: 550ms; }

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms; }

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0s; }
  body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
    transition-delay: 600ms; }

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms; }

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0s; }
  body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
    transition-delay: 650ms; }

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms; }

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0s; }
  body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
    transition-delay: 700ms; }

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms; }

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0s; }
  body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
    transition-delay: 750ms; }

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms; }

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0s; }
  body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
    transition-delay: 800ms; }

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms; }

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0s; }
  body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
    transition-delay: 850ms; }

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms; }

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0s; }
  body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
    transition-delay: 900ms; }

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms; }

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0s; }
  body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
    transition-delay: 950ms; }

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms; }

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0s; }
  body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
    transition-delay: 1000ms; }

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms; }

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0s; }
  body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
    transition-delay: 1050ms; }

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms; }

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0s; }
  body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
    transition-delay: 1100ms; }

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms; }

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0s; }
  body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
    transition-delay: 1150ms; }

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms; }

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0s; }
  body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
    transition-delay: 1200ms; }

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms; }

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0s; }
  body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
    transition-delay: 1250ms; }

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms; }

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0s; }
  body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
    transition-delay: 1300ms; }

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms; }

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0s; }
  body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
    transition-delay: 1350ms; }

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms; }

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0s; }
  body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
    transition-delay: 1400ms; }

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms; }

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0s; }
  body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
    transition-delay: 1450ms; }

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms; }

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0s; }
  body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
    transition-delay: 1500ms; }

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms; }

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0s; }
  body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
    transition-delay: 1550ms; }

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms; }

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0s; }
  body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
    transition-delay: 1600ms; }

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms; }

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0s; }
  body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
    transition-delay: 1650ms; }

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms; }

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0s; }
  body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
    transition-delay: 1700ms; }

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms; }

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0s; }
  body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
    transition-delay: 1750ms; }

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms; }

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0s; }
  body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
    transition-delay: 1800ms; }

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms; }

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0s; }
  body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
    transition-delay: 1850ms; }

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms; }

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0s; }
  body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
    transition-delay: 1900ms; }

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms; }

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0s; }
  body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
    transition-delay: 1950ms; }

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms; }

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0s; }
  body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
    transition-delay: 2000ms; }

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms; }

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0s; }
  body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
    transition-delay: 2050ms; }

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms; }

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0s; }
  body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
    transition-delay: 2100ms; }

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms; }

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0s; }
  body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
    transition-delay: 2150ms; }

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms; }

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0s; }
  body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
    transition-delay: 2200ms; }

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms; }

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0s; }
  body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
    transition-delay: 2250ms; }

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms; }

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0s; }
  body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
    transition-delay: 2300ms; }

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms; }

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0s; }
  body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
    transition-delay: 2350ms; }

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms; }

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0s; }
  body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
    transition-delay: 2400ms; }

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms; }

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0s; }
  body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
    transition-delay: 2450ms; }

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms; }

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0s; }
  body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
    transition-delay: 2500ms; }

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms; }

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0s; }
  body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
    transition-delay: 2550ms; }

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms; }

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0s; }
  body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
    transition-delay: 2600ms; }

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms; }

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0s; }
  body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
    transition-delay: 2650ms; }

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms; }

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0s; }
  body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
    transition-delay: 2700ms; }

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms; }

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0s; }
  body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
    transition-delay: 2750ms; }

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms; }

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0s; }
  body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
    transition-delay: 2800ms; }

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms; }

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0s; }
  body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
    transition-delay: 2850ms; }

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms; }

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0s; }
  body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
    transition-delay: 2900ms; }

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms; }

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0s; }
  body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
    transition-delay: 2950ms; }

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms; }

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0s; }
  body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
    transition-delay: 3000ms; }

[data-aos] {
  pointer-events: none; }
  [data-aos].aos-animate {
    pointer-events: auto; }

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1); }

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1); }

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1); }

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1); }

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

@media screen {
  html:not(.no-js) {
    /**
    * Fade animations:
    * fade
    * fade-up, fade-down, fade-left, fade-right
    * fade-up-right, fade-up-left, fade-down-right, fade-down-left
    */
    /**
    * Zoom animations:
    * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
    * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
    */
    /**
    * Slide animations
    */
    /**
    * Flip animations:
    * flip-left, flip-right, flip-up, flip-down
    */ }
    html:not(.no-js) [data-aos^='fade'][data-aos^='fade'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='fade'][data-aos^='fade'].aos-animate {
        opacity: 1;
        transform: none; }
    html:not(.no-js) [data-aos='fade-up'] {
      transform: translate3d(0, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down'] {
      transform: translate3d(0, -100px, 0); }
    html:not(.no-js) [data-aos='fade-right'] {
      transform: translate3d(-100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-left'] {
      transform: translate3d(100px, 0, 0); }
    html:not(.no-js) [data-aos='fade-up-right'] {
      transform: translate3d(-100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-up-left'] {
      transform: translate3d(100px, 100px, 0); }
    html:not(.no-js) [data-aos='fade-down-right'] {
      transform: translate3d(-100px, -100px, 0); }
    html:not(.no-js) [data-aos='fade-down-left'] {
      transform: translate3d(100px, -100px, 0); }
    html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'] {
      opacity: 0;
      transition-property: opacity, transform; }
      html:not(.no-js) [data-aos^='zoom'][data-aos^='zoom'].aos-animate {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(1); }
    html:not(.no-js) [data-aos='zoom-in'] {
      transform: scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-up'] {
      transform: translate3d(0, 100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-down'] {
      transform: translate3d(0, -100px, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-right'] {
      transform: translate3d(-100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-in-left'] {
      transform: translate3d(100px, 0, 0) scale(0.6); }
    html:not(.no-js) [data-aos='zoom-out'] {
      transform: scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-up'] {
      transform: translate3d(0, 100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-down'] {
      transform: translate3d(0, -100px, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-right'] {
      transform: translate3d(-100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos='zoom-out-left'] {
      transform: translate3d(100px, 0, 0) scale(1.2); }
    html:not(.no-js) [data-aos^='slide'][data-aos^='slide'] {
      transition-property: transform;
      visibility: hidden; }
      html:not(.no-js) [data-aos^='slide'][data-aos^='slide'].aos-animate {
        visibility: visible;
        transform: translate3d(0, 0, 0); }
    html:not(.no-js) [data-aos='slide-up'] {
      transform: translate3d(0, 100%, 0); }
    html:not(.no-js) [data-aos='slide-down'] {
      transform: translate3d(0, -100%, 0); }
    html:not(.no-js) [data-aos='slide-right'] {
      transform: translate3d(-100%, 0, 0); }
    html:not(.no-js) [data-aos='slide-left'] {
      transform: translate3d(100%, 0, 0); }
    html:not(.no-js) [data-aos^='flip'][data-aos^='flip'] {
      backface-visibility: hidden;
      transition-property: transform; }
    html:not(.no-js) [data-aos='flip-left'] {
      transform: perspective(2500px) rotateY(-100deg); }
      html:not(.no-js) [data-aos='flip-left'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-right'] {
      transform: perspective(2500px) rotateY(100deg); }
      html:not(.no-js) [data-aos='flip-right'].aos-animate {
        transform: perspective(2500px) rotateY(0); }
    html:not(.no-js) [data-aos='flip-up'] {
      transform: perspective(2500px) rotateX(-100deg); }
      html:not(.no-js) [data-aos='flip-up'].aos-animate {
        transform: perspective(2500px) rotateX(0); }
    html:not(.no-js) [data-aos='flip-down'] {
      transform: perspective(2500px) rotateX(100deg); }
      html:not(.no-js) [data-aos='flip-down'].aos-animate {
        transform: perspective(2500px) rotateX(0); } }

/**
* Breakpoints
*/
/*

@include media(">=phone-small") {

}

@include media(">=phone-large") {

}

@include media(">=tablet") {

}

@include media(">=laptop") {

}

@include media(">=desktop") {

}

@include media(">=4k") {

}

*/
/**
* Fonts (@font-face)
*/
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-ExtraLightItalic.ttf") format("ttf");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-LightItalic.ttf") format("ttf");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Italic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-SemiBoldItalic.ttf") format("ttf");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-BoldItalic.ttf") format("ttf");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal; }

/**
* Variables
*/
/**
* Global styles
*/
/*
* {
  box-sizing: border-box; // consistent box-sizing for all elements
}

html {
  //height: 100%; // for sticky footer
  font-size: 100%; // set root font-size for rem units (100% equates to 16px)
  line-height: 1.7; // set default line height
  height: 100%;
  //height: 100vh; // for gallery
}

body {
  //min-height: 100%; // for sticky footer
  //display: grid; // for sticky footer
  //grid-template-rows: auto 1fr auto; // for sticky footer
  margin: 0; // make sure body element is edge-to-edge
  background-color: $bodyColor;
  color: $fontColor;
  font-family: "Roboto", sans-serif;

  height: 100%; //for gallery
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: $headerHeight 1fr $footerHeight;
}

h1, h2, h3, h4, h5, h6 {

}

h1 {
  font-size: 2.2rem;
  margin-bottom: 1.4rem;
}

h2 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.0rem;
  margin-bottom: 1rem;
}

p {

}

a {
  text-decoration: none;
  color: $linkColor;

  &:link {

  }

  &:hover {

  }

  &:active {

  }

  &:visited {

  }
}

:focus {
//  outline: 3px solid orange;
}

img {

}

.wrap {
  width: 100%;
  height: auto;
}
  .wrapInner {
    width: 100%;
    max-width: $siteWidth;
    margin: 0 auto;
    //background-color: $mainColor;
    //padding: 1rem;
  }

*/
/**
  * Global styles
  */
* {
  box-sizing: border-box; }

html {
  height: 100%;
  font-size: 100%;
  line-height: 1.7; }

body {
  min-height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 80px 1fr 60px;
  margin: 0;
  background-color: #222;
  color: #ddd;
  font-family: 'Titillium Web', sans-serif; }

h1 {
  font-size: 2.2rem;
  margin-bottom: 1.4rem; }

h2 {
  font-size: 1.6rem;
  margin-bottom: 1rem; }

h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem; }

h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem; }

h5 {
  font-size: 1.1rem;
  margin-bottom: 1rem; }

h6 {
  font-size: 1.0rem;
  margin-bottom: 1rem; }

a {
  text-decoration: none;
  color: #fff; }

ul, ol {
  margin: 0;
  padding: 0;
  list-style-position: inside; }

/* begin button styles */
button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none; }

/* end button styles */
.wrap {
  width: 100%;
  height: auto; }

.innerWrap {
  width: 100%;
  max-width: 100%;
  margin: 0 auto; }

.container {
  padding-left: 20px;
  padding-right: 20px;
  transition: padding 300ms; }
  @media (min-width: 480px) {
    .container {
      padding: 0 40px; } }
  @media (min-width: 768px) {
    .container {
      padding: 0 60px; } }
  @media (min-width: 992px) {
    .container {
      padding: 0 80px; } }
  @media (min-width: 1200px) {
    .container {
      padding: 0 100px; } }

.page {
  max-width: 900px;
  padding-bottom: 60px; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.noScroll {
  overflow: hidden; }

/**
* Header styles
*/
/*
#wrapHeader {
  //background-color: #eee;
  //position: fixed;
  //top: 0;
}
  #header { // flex container
    height: $headerHeight;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //background-color: #222;
    border-bottom: 2px solid #111;
    z-index: 1100;
    //box-shadow:         0px 0px 5px 5px #fff;

    @include media(">=tablet") {
      flex-wrap: nowrap;
    }
  }
    #headerLeft { // flex item
      //padding: 1rem 1rem 1rem 1.5rem;
      padding-left: 1.5rem;
      flex-basis: 75%;
      order: 1;
      display: grid;
      align-content: center;
      //background-color: #ddd;

      @include media(">=phone-large") {
        //padding: 1rem 1rem 1rem 2rem;
        padding-left: 2rem;
      }

      @include media(">=tablet") {
        flex-basis: 50%;
      }
    }
      .logoText {
        //margin-left: 0.8rem;
        //font-size: 1.2rem;
        color: $fontColor;
      }

    #navMain { // flex item
      padding: 0;
      flex-basis: 100%;
      order: 3;
      display: none;
      z-index: 1000;
      border-bottom: 2px solid #151515;
      box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.5);

      @include media(">=tablet") {
        //padding: 1rem;
        flex-basis: 50%;
        order: 2;
        display: block;
        border-bottom: unset;
        box-shadow: unset;
      }

// make sure flex settings are correct:
      ul { // flex container
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        list-style: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #151515;
        border-bottom: 10px solid #222;

        @include media(">=tablet") {
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          background-color: unset;
        }

        li { // flex item AND container
          width: 100%;
          height: 60px;
          //padding: 0.7rem 1rem;
          list-style: none;
          display: flex;
          align-items: center;
          border-top: 2px solid #222;
          border-bottom: 2px solid #111;

          &:hover {
            background-color: #181818;
          }

          &:first-child {
            border-top: unset;
          }

          &:last-child {
            border-bottom: 2px solid #333;
          }

          @include media(">=tablet") {
            width: auto;
            padding: 0 1rem;
            border-bottom: unset;

            &:hover {
              background-color: unset;
            }

            &:last-child {
              border-bottom: unset;
            }
          }

          a {
            width: 100%;
            text-align: center;
            color: $fontColor;

            &:hover {
              color: #fff;
            }

            @include media(">=tablet") {
              width: auto;
            }
          }
        }
      }
    }

    #headerRight { // flex item
      padding: 1rem 2rem 1rem 1rem;
      flex-basis: 25%;
      order: 2;
      //background-color: #ddd;
      display: flex;
      align-items: center;
      justify-content: right;
      align-content: center;

      @include media(">=tablet") {
        flex-basis: 0%;
        padding: 0;
        order: 3
      }
    }
      .menuBurger {
        //margin-right: 0.8rem;
        display: block;
        cursor: pointer;

        @include media(">=tablet") {
          display: none;
        }

        i, span {
          color: $fontColor;
          font-size: 1.3rem;
        }
      }


*/
/**
      * Header styles
      */
.headerWrap {
  background-color: #222;
  height: 80px; }

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 80px;
  position: relative; }
  @media (min-width: 768px) {
    .header {
      flex-wrap: nowrap; } }

.logo {
  display: flex;
  flex-basis: 1fr;
  flex-grow: 1;
  order: 1;
  align-items: center;
  z-index: 1001; }
  @media (min-width: 768px) {
    .logo {
      flex-basis: 35%; } }
  .logo a {
    display: flex;
    align-items: center; }

.logoImage {
  height: 40px;
  width: auto;
  animation: rotation 20s infinite linear; }

@keyframes rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.logoText {
  padding-left: 0.8rem;
  font-size: 1rem;
  color: #aaa;
  font-weight: bold;
  text-transform: uppercase; }

.headerNav {
  width: 100%;
  height: 0;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  display: none;
  flex-basis: 100%;
  order: 3;
  background-color: #222;
  align-items: center;
  transition: height 300ms linear; }
  @media (min-width: 768px) {
    .headerNav {
      width: auto;
      height: auto;
      position: static;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      display: flex;
      flex-basis: 65%;
      order: 2;
      background-color: unset;
      justify-content: flex-end; } }
  .headerNav ul {
    height: auto;
    margin: 0 auto;
    display: flex;
    list-style: flex;
    flex-direction: column;
    flex-wrap: wrap;
    position: relative; }
    @media (min-width: 768px) {
      .headerNav ul {
        height: 100%;
        margin: 0;
        flex-direction: row;
        flex-wrap: nowrap;
        display: flex;
        column-gap: 2rem; } }
    .headerNav ul li {
      width: auto;
      margin: 0 auto;
      padding: 0;
      list-style: none;
      display: flex;
      align-items: center;
      position: relative; }
      @media (min-width: 768px) {
        .headerNav ul li {
          width: auto;
          margin: 0; } }
      .headerNav ul li a {
        width: auto;
        height: auto;
        display: block;
        text-align: center;
        color: #aaa;
        padding: 0.7rem 0;
        margin: 0; }
        @media (min-width: 768px) {
          .headerNav ul li a {
            height: 100%;
            width: auto;
            margin: 0;
            display: flex;
            align-items: center;
            padding: 0; } }
        .headerNav ul li a:after {
          content: "";
          height: 3px;
          width: 100%;
          position: absolute;
          bottom: 5px;
          left: 0;
          background-color: #aaa;
          display: block;
          opacity: 0;
          transition: opacity 100ms ease-in-out;
          border-radius: 2px; }
          @media (min-width: 768px) {
            .headerNav ul li a:after {
              display: none; } }
        .headerNav ul li a:hover:after {
          opacity: 1.0; }
    .headerNav ul li.slide-line {
      position: absolute;
      z-index: 1002;
      bottom: 20px;
      left: 0;
      width: 0;
      height: 3px;
      padding: 0;
      margin: 0;
      background-color: #aaa;
      border-radius: 2px;
      transition: width 300ms, left 300ms; }
      .headerNav ul li.slide-line:before {
        display: none; }

.burger {
  z-index: 1001;
  display: flex;
  flex-basis: auto;
  order: 2;
  justify-self: end;
  justify-content: right;
  align-items: center;
  cursor: pointer; }
  @media (min-width: 768px) {
    .burger {
      display: none;
      flex-basis: 0;
      order: 3; } }
  .burger i, .burger span {
    color: #aaa;
    font-size: 1.3rem; }

/**
* Content styles
*/
#main {
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  height: 100%;
  padding-bottom: 15px; }

.contentPadded {
  max-width: 1200px;
  padding: 2rem;
  margin: 0 auto; }

.mainWrap {
  background-color: #222; }

.main {
  height: 100%;
  overflow: hidden;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1; }

/**
* Footer styles
*/
/*
#wrapFooter {

  //grid-row-start: 3; // for sticky footer
  //grid-row-end: 4; // for sticky footer
}
  #footer {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    position: fixed;
    bottom: 0;
    height: $footerHeight;
    //background-color: #111;
    text-align: center;
    overflow: hidden; // extends height to accomodate content's margin
    font-size: 0.8rem;
    display: grid;
    align-content: center;
    border-top: 2px solid #333;
    color: #bbb;
  }
*/
.footerWrap {
  grid-row-start: 3;
  grid-row-end: 4;
  bottom: 0;
  height: 60px;
  text-align: center;
  overflow: hidden;
  font-size: 0.8rem;
  display: grid;
  align-content: center;
  color: #bbb;
  position: fixed; }

body.info .footerWrap {
  position: relative;
  background-color: #111; }

.footer {
  text-align: center;
  overflow: hidden; }

.copyright {
  margin: 0;
  font-size: 10px;
  color: #888; }

/**
* Miscellaneous styles
*/
.gsapTest {
  width: 100px;
  height: 100px;
  background-color: red; }

.gallery {
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 60px auto; }

.galleryDisplay {
  height: 100%;
  position: relative;
  touch-action: none;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  display: grid;
  align-content: center; }

.galleryDisplayPort {
  width: auto;
  margin: 0 auto;
  transition: background-color 2.5s ease;
  background-color: #ffffff; }

img.galleryDisplayImage {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  outline: 4px solid #000;
  outline-offset: -4px;
  mix-blend-mode: multiply;
  opacity: 100%; }
  @media (min-width: 768px) {
    img.galleryDisplayImage {
      outline: 8px solid #000;
      outline-offset: -8px; } }

.galleryDisplayMagnifier {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  outline: 4px solid rgba(255, 255, 255, 0.1);
  outline-offset: -4px;
  /*
        border-top: 1px solid rgba(230, 230, 230, 0.5);
        border-right: 1px solid rgba(170, 170, 170, 0.5);
        border-bottom: 1px solid rgba(100, 100, 100, 0.5);
        border-left: 1px solid rgba(170, 170, 170, 0.5);
        border-radius: 1px;
        */
  border-radius: 10px;
  transition: background-color 2.5s ease;
  background-color: #ffffff;
  background-blend-mode: multiply;
  opacity: 100%; }

.galleryInfo {
  width: 100%;
  max-width: 100%;
  height: auto;
  padding: 12px;
  font-size: 1rem;
  color: #aaa;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  text-align: center; }

.galleryInfoTitle {
  font-style: italic;
  font-weight: bold; }

.galleryControl {
  width: auto;
  max-width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 0;
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
  display: flex; }

.galleryControlButton {
  width: 90px;
  height: 60px;
  display: block;
  position: absolute;
  top: 0;
  cursor: pointer; }

.galleryControlPrevious {
  left: 0;
  z-index: 99;
  background-image: linear-gradient(to left, rgba(34, 34, 34, 0), #222222), linear-gradient(to left, #222222, #222222), linear-gradient(to left, #222222, #222222);
  background-size: 33.3333333% 100%, 33.3333333% 100%, 33.3333333% 100%;
  background-position: right, center, left;
  background-repeat: no-repeat; }

.galleryControlPreviousArrow {
  width: 0;
  height: 0;
  margin: 0 30px;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid #aaa;
  transition: border-right 300ms; }
  .galleryControlPreviousArrow:hover {
    border-right: 30px solid #ddd; }

.galleryControlNext {
  right: 0;
  z-index: 99;
  background-image: linear-gradient(to right, rgba(34, 34, 34, 0), #222222), linear-gradient(to right, #222222, #222222), linear-gradient(to right, #222222, #222222);
  background-size: 33.3333333% 100%, 33.3333333% 100%, 33.3333333% 100%;
  background-position: left, center, right;
  background-repeat: no-repeat; }

.galleryControlNextArrow {
  width: 0;
  height: 0;
  margin: 0 30px;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #aaa;
  transition: border-left 300ms; }
  .galleryControlNextArrow:hover {
    border-left: 30px solid #ddd; }

.galleryControlSubjects {
  width: 100%;
  height: 60px;
  margin: 0 auto;
  padding: 0 75px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
  z-index: 1; }
  .galleryControlSubjects ul.galleryControlSubjectList {
    width: auto;
    height: 60px;
    margin: 0;
    padding: 0;
    display: inline-block;
    list-style: none;
    text-align: center;
    transition: 0.3s ease-in-out; }
    .galleryControlSubjects ul.galleryControlSubjectList li.galleryControlSubjectItem {
      width: 60px;
      height: 60px;
      margin: 0 5px;
      padding: 0;
      display: inline-block;
      list-style: none;
      background-color: white;
      border-radius: 50%; }
      .galleryControlSubjects ul.galleryControlSubjectList li.galleryControlSubjectItem img.galleryControlSubjectImage {
        width: 60px;
        height: 60px;
        object-fit: cover;
        object-position: center;
        display: block;
        cursor: pointer;
        border-radius: 50%;
        outline: 6px solid #222;
        outline-offset: -3px;
        transition: opacity 400ms; }
        .galleryControlSubjects ul.galleryControlSubjectList li.galleryControlSubjectItem img.galleryControlSubjectImage:hover {
          opacity: 0.8; }

.galleryControlSubjectImageActive {
  opacity: 0.4;
  outline: 6px solid #fff;
  outline-offset: -3px; }
  .galleryControlSubjectImageActive:hover {
    opacity: 0.4 !important; }

.galleryLighting {
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  z-index: 0;
  grid-column: 1 / span 1;
  grid-row: 4 / span 1; }

.galleryLightingList {
  width: auto;
  height: auto;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  text-align: center;
  transition: 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.galleryLightingItem {
  width: 40px;
  height: 40px;
  margin: 0 5px;
  padding: 0;
  display: inline-block;
  box-sizing: content-box;
  list-style: none;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  transition: box-shadow 0.5s ease, color 0.5s ease;
  box-shadow: inset 0px 0px 30px 12px #282828;
  align-content: center;
  line-height: 40px;
  mix-blend-mode: soft-light;
  opacity: 100%; }
  .galleryLightingItem:hover {
    box-shadow: inset 0px 0px 30px 12px rgba(40, 40, 40, 0.8); }
  .galleryLightingItem:nth-child(5n) {
    page-break-after: always;
    /* CSS 2.1 syntax */
    break-after: always;
    /* New syntax */ }
    @media (min-width: 480px) {
      .galleryLightingItem:nth-child(5n) {
        page-break-after: auto;
        /* CSS 2.1 syntax */
        break-after: auto;
        /* New syntax */ } }
  .galleryLightingItem:nth-child(6n), .galleryLightingItem:nth-child(7n), .galleryLightingItem:nth-child(8n), .galleryLightingItem:nth-child(9n), .galleryLightingItem:nth-child(10n) {
    margin-top: 10px; }
    @media (min-width: 480px) {
      .galleryLightingItem:nth-child(6n), .galleryLightingItem:nth-child(7n), .galleryLightingItem:nth-child(8n), .galleryLightingItem:nth-child(9n), .galleryLightingItem:nth-child(10n) {
        margin-top: 0; } }

.galleryLightingColor1:after, .galleryLightingColor2:after, .galleryLightingColor3:after, .galleryLightingColor4:after, .galleryLightingColor5:after, .galleryLightingColor6:after, .galleryLightingColor7:after {
  content: "";
  width: 10px;
  height: 0px;
  border-bottom: 2px solid #333;
  border-top: 2px solid #111;
  display: block;
  position: relative;
  left: 41px;
  bottom: 22px; }

.galleryLightingColor1 {
  background-color: #ffffff; }

.galleryLightingColor2 {
  background-color: #ff0000; }

.galleryLightingColor3 {
  background-color: #00ff00; }

.galleryLightingColor4 {
  background-color: #0000ff; }

.galleryLightingColor5 {
  background-color: #ff00ff; }

.galleryLightingColor6 {
  background-color: #ffff00; }

.galleryLightingColor7 {
  background-color: #00ffff; }

.galleryLightingOptionCycle {
  background-image: conic-gradient(red, yellow, green, cyan, blue, magenta);
  position: relative;
  color: #fff; }
  .galleryLightingOptionCycle i {
    position: relative;
    left: 2px; }

.galleryLightingOptionUV {
  background-color: #2200aa;
  color: #fff;
  margin-left: 10px; }

.galleryLightingColorActive {
  box-shadow: unset;
  color: #000; }
  .galleryLightingColorActive:hover {
    box-shadow: unset; }

.galleryLightingOptionCycleActive {
  box-shadow: unset;
  color: #000; }
  .galleryLightingOptionCycleActive i {
    position: relative;
    left: 0px; }

.galleryLightingOptionUVActive {
  box-shadow: unset;
  color: #ff00ff; }

/*
      @keyframes galleryLightingAnimatedBg {
        0% { background-color: red; }
        16% { background-color: yellow; }
        33% { background-color: green;}
        50% { background-color: cyan; }
        66% { background-color: blue; }
        83% { background-color: magenta; }
        100% { background-color: red; }
      }

      .galleryLightingAnimated {
        animation: galleryLightingAnimatedBg 10s infinite;
      }
      */
