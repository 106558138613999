/**
* Footer styles
*/

/*
#wrapFooter {

  //grid-row-start: 3; // for sticky footer
  //grid-row-end: 4; // for sticky footer
}
  #footer {
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;
    position: fixed;
    bottom: 0;
    height: $footerHeight;
    //background-color: #111;
    text-align: center;
    overflow: hidden; // extends height to accomodate content's margin
    font-size: 0.8rem;
    display: grid;
    align-content: center;
    border-top: 2px solid #333;
    color: #bbb;
  }
*/

  .footerWrap {
    //grid-column: 1 / span 1;
    //grid-row: 3 / span 1;
    grid-row-start: 3; // for sticky footer
    grid-row-end: 4; // for sticky footer
    //border-top: 2px solid #333;

    bottom: 0;
    height: $footerHeight;
    //background-color: #111;
    text-align: center;
    overflow: hidden; // extends height to accomodate content's margin
    font-size: 0.8rem;
    display: grid;
    align-content: center;
    //border-top: 2px solid lighten($baseColor, 10%);
    color: #bbb;
    position: fixed;

    ///background-color: #111;
  }

  body.info { // every non-gallery page

    .footerWrap {
      position: relative; // unfix the footer
      background-color: #111;
    }
  }

    .footer {
      text-align: center;
      overflow: hidden; // extends height to accomodate content's margin
      //padding-top: 2rem;
      //padding-bottom: 2rem;
    }
      .copyright {
        margin: 0;
        font-size: 10px;
        color: #888;
      }
