/**
* Global styles
*/

/*
* {
  box-sizing: border-box; // consistent box-sizing for all elements
}

html {
  //height: 100%; // for sticky footer
  font-size: 100%; // set root font-size for rem units (100% equates to 16px)
  line-height: 1.7; // set default line height
  height: 100%;
  //height: 100vh; // for gallery
}

body {
  //min-height: 100%; // for sticky footer
  //display: grid; // for sticky footer
  //grid-template-rows: auto 1fr auto; // for sticky footer
  margin: 0; // make sure body element is edge-to-edge
  background-color: $bodyColor;
  color: $fontColor;
  font-family: "Roboto", sans-serif;

  height: 100%; //for gallery
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: $headerHeight 1fr $footerHeight;
}

h1, h2, h3, h4, h5, h6 {

}

h1 {
  font-size: 2.2rem;
  margin-bottom: 1.4rem;
}

h2 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

h5 {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

h6 {
  font-size: 1.0rem;
  margin-bottom: 1rem;
}

p {

}

a {
  text-decoration: none;
  color: $linkColor;

  &:link {

  }

  &:hover {

  }

  &:active {

  }

  &:visited {

  }
}

:focus {
//  outline: 3px solid orange;
}

img {

}

.wrap {
  width: 100%;
  height: auto;
}
  .wrapInner {
    width: 100%;
    max-width: $siteWidth;
    margin: 0 auto;
    //background-color: $mainColor;
    //padding: 1rem;
  }

*/



  /**
  * Global styles
  */

  * {
    box-sizing: border-box; // consistent box-sizing for all elements
  }

  html {
    height: 100%; // for sticky footer
    font-size: 100%; // set root font-size for rem units (100% equates to 16px)
    line-height: 1.7; // set default line height
  }

  body {
    min-height: 100%; // for sticky footer
    display: grid; // for sticky footer
    grid-template-columns: auto;
    grid-template-rows: $headerHeight 1fr $footerHeight;
    margin: 0; // make sure body element is edge-to-edge
    background-color: $bodyColor;
    color: $fontColor;
    font-family: 'Titillium Web', sans-serif;

    @include media(">=tablet") {
      //grid-template-rows: 100px 1fr $footerHeight;
    }
  }

  h1, h2, h3, h4, h5, h6 {

  }

  h1 {
    font-size: 2.2rem;
    margin-bottom: 1.4rem;
  }

  h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }

  h4 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  h5 {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }

  h6 {
    font-size: 1.0rem;
    margin-bottom: 1rem;
  }

  p {

  }

  a {
    text-decoration: none;
    color: $linkColor;

    &:link {

    }

    &:hover {

    }

    &:active {

    }

    &:visited {

    }
  }

  :focus {
  //  outline: 3px solid orange;
  }

  ul, ol {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

  img {

  }

  /* begin button styles */
  button {
    display: inline-block;
    border: none;
    //padding: 1rem 2rem;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    //background: #0069ed;
    //color: #ffffff;
    //font-family: sans-serif;
    //font-size: 1rem;
    cursor: pointer;
    //text-align: center;
    //transition: background 250ms ease-in-out,
    //            transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  button:hover,
  button:focus {
      //background: #0053ba;
  }

  button:focus {
      //outline: 1px solid #fff;
      //outline-offset: -4px;
  }

  button:active {
      //transform: scale(0.99);
  }
  /* end button styles */

  .wrap {
    width: 100%;
    height: auto;
  }

  .innerWrap {
    width: 100%;
    max-width: $siteWidth;
    margin: 0 auto;
  }

  .container {
    padding-left: 20px; //2rem;
    padding-right: 20px; //2rem;
    transition: padding 300ms;

    @include media(">=phone-large") {
      padding: 0 40px;
    }

    @include media(">=tablet") {
      padding: 0 60px;
    }

    @include media(">=laptop") {
      padding: 0 80px;
    }

    @include media(">=desktop") {
      padding: 0 100px;
    }
  }

  .page {
    max-width: 900px;
    padding-bottom: 60px;
  }

  .sr-only { // visible to screen readers only
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .noScroll {
    overflow: hidden;
  }
