/**
* Header styles
*/
/*
#wrapHeader {
  //background-color: #eee;
  //position: fixed;
  //top: 0;
}
  #header { // flex container
    height: $headerHeight;
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    //background-color: #222;
    border-bottom: 2px solid #111;
    z-index: 1100;
    //box-shadow:         0px 0px 5px 5px #fff;

    @include media(">=tablet") {
      flex-wrap: nowrap;
    }
  }
    #headerLeft { // flex item
      //padding: 1rem 1rem 1rem 1.5rem;
      padding-left: 1.5rem;
      flex-basis: 75%;
      order: 1;
      display: grid;
      align-content: center;
      //background-color: #ddd;

      @include media(">=phone-large") {
        //padding: 1rem 1rem 1rem 2rem;
        padding-left: 2rem;
      }

      @include media(">=tablet") {
        flex-basis: 50%;
      }
    }
      .logoText {
        //margin-left: 0.8rem;
        //font-size: 1.2rem;
        color: $fontColor;
      }

    #navMain { // flex item
      padding: 0;
      flex-basis: 100%;
      order: 3;
      display: none;
      z-index: 1000;
      border-bottom: 2px solid #151515;
      box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.5);

      @include media(">=tablet") {
        //padding: 1rem;
        flex-basis: 50%;
        order: 2;
        display: block;
        border-bottom: unset;
        box-shadow: unset;
      }

// make sure flex settings are correct:
      ul { // flex container
        height: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        list-style: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        background-color: #151515;
        border-bottom: 10px solid #222;

        @include media(">=tablet") {
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          background-color: unset;
        }

        li { // flex item AND container
          width: 100%;
          height: 60px;
          //padding: 0.7rem 1rem;
          list-style: none;
          display: flex;
          align-items: center;
          border-top: 2px solid #222;
          border-bottom: 2px solid #111;

          &:hover {
            background-color: #181818;
          }

          &:first-child {
            border-top: unset;
          }

          &:last-child {
            border-bottom: 2px solid #333;
          }

          @include media(">=tablet") {
            width: auto;
            padding: 0 1rem;
            border-bottom: unset;

            &:hover {
              background-color: unset;
            }

            &:last-child {
              border-bottom: unset;
            }
          }

          a {
            width: 100%;
            text-align: center;
            color: $fontColor;

            &:hover {
              color: #fff;
            }

            @include media(">=tablet") {
              width: auto;
            }
          }
        }
      }
    }

    #headerRight { // flex item
      padding: 1rem 2rem 1rem 1rem;
      flex-basis: 25%;
      order: 2;
      //background-color: #ddd;
      display: flex;
      align-items: center;
      justify-content: right;
      align-content: center;

      @include media(">=tablet") {
        flex-basis: 0%;
        padding: 0;
        order: 3
      }
    }
      .menuBurger {
        //margin-right: 0.8rem;
        display: block;
        cursor: pointer;

        @include media(">=tablet") {
          display: none;
        }

        i, span {
          color: $fontColor;
          font-size: 1.3rem;
        }
      }


*/





      /**
      * Header styles
      */

      .headerWrap {
        background-color: $baseColor;
        height: $headerHeight;
        //border-bottom: 2px solid darken($baseColor, 10%);

        @include media(">=tablet") {
          //height: 100px;
        }
      }
        .header {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: $headerHeight;
          position: relative;

          @include media(">=tablet") {
            flex-wrap: nowrap;
            //height: 100px;
          }
        }
          .logo {
            display: flex;
            flex-basis: 1fr;
            flex-grow: 1;
            order: 1;
            align-items: center;
            z-index: 1001;

            @include media(">=tablet") {
              flex-basis: 35%;
            }

            a {
              display: flex;
              align-items: center;
            }
          }
            .logoImage {
              height: 40px;
              width: auto;
              //padding: 10px 0;
              animation: rotation 20s infinite linear;
              //transition: animation 700ms;

              @keyframes rotation {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(359deg);
                }
              }

              @include media(">=tablet") {
                //height: 60px;
              }

              &:hover {
                //animation: rotation 1s infinite linear;
              }
            }

            .logoText {
              padding-left: 0.8rem;
              font-size: 1rem;
              color: #aaa;
              font-weight: bold;
              text-transform: uppercase;

              @include media(">=tablet") {
                //font-size: 1rem;
              }
            }

          .headerNav {
            width: 100%;
            height: 0;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1000;
            display: none;
            flex-basis: 100%;
            order: 3;
            background-color: #222;
            align-items: center;
            transition: height 300ms linear;

            @include media(">=tablet") {
              width: auto;
              height: auto;
              position: static;
              top: auto;
              right: auto;
              bottom: auto;
              left: auto;
              display: flex;
              flex-basis: 65%;
              order: 2;
              background-color: unset;
              justify-content: flex-end;
            }

            ul {
              height: auto;
              margin: 0 auto;
              display: flex;
              list-style: flex;
              flex-direction: column;
              flex-wrap: wrap;
              position: relative;
              //column-gap: 0;

              @include media(">=tablet") {
                height: 100%;
                margin: 0;
                flex-direction: row;
                flex-wrap: nowrap;
                display: flex;
                column-gap: 2rem;
              }

              li {
                width: auto;
                margin: 0 auto;
                padding: 0;
                list-style: none;
                display: flex;
                align-items: center;
                position: relative;

                @include media(">=tablet") {
                  width: auto;
                  margin: 0;
                }

                a {
                  width: auto;
                  height: auto;
                  display: block;
                  text-align: center;
                  color: #aaa;
                  padding: 0.7rem 0;
                  margin: 0;

                  @include media(">=tablet") {
                    height: 100%;
                    width: auto;
                    margin: 0;// 10px;
                    display: flex;
                    align-items: center;
                    padding: 0;
                  }

                  &:after { // active at mobile sizes
                    content: "";
                    height: 3px;
                    width: 100%;
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    background-color: #aaa;
                    display: block;
                    opacity: 0;
                    transition: opacity 100ms ease-in-out;
                    border-radius: 2px;

                    @include media(">=tablet") {
                      display: none;
                    }
                  }

                  &:hover {

                    &:after {
                      opacity: 1.0;
                    }
                  }
                }
              }

              li.slide-line { // active at non-mobile sizes
                //display: none;
                position: absolute;
                z-index: 1002;
                bottom: 20px;
                left: 0;
                width: 0;
                height: 3px;
                padding: 0;
                margin: 0;
                background-color: #aaa;
                border-radius: 2px;
                transition: width 300ms, left 300ms;

                &:before {
                  display: none;
                }
              }
            }
          }

          .current_page_item {

            a {

            }
          }

          .burger {
            z-index: 1001;
            display: flex;
            flex-basis: auto;
            order: 2;
            justify-self: end;
            justify-content: right;
            align-items: center;
            cursor: pointer;

            @include media(">=tablet") {
              display: none;
              flex-basis: 0;
              order: 3;
            }

            i, span {
              color: #aaa;
              font-size: 1.3rem;
            }
          }
