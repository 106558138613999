/**
* Content styles
*/

#wrapMain {

}
  #main {
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    //height: 100%; // for sticky footer: fill any empty space down to footer
    height: 100%; //calc(100vh - ($headerHeight + $footerHeight));
    //overflow: hidden; // extends height to accomodate content's margin
    //border-top: 2px solid #333;
    //border-bottom: 2px solid #111;
    padding-bottom: 15px;
  }
    .contentPadded {
      max-width: 1200px;
      padding: 2rem;
      margin: 0 auto;
    }


    .mainWrap {
      //border-top: 2px solid #333;
      //border-bottom: 2px solid #111;
      background-color: $baseColor;

      //border-top: 2px solid lighten($baseColor, 10%);
      //border-bottom: 2px solid darken($baseColor, 10%);
    }
      .main {
        height: 100%; // for sticky footer: fill any empty space down to footer
        overflow: hidden; // extends height to accomodate content's margin
        //padding-top: 2rem;
        //padding-bottom: 2rem;


        //padding-bottom: 15px;
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
      }
