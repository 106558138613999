/**
* Fonts (@font-face)
*/


// TitilliumWeb-ExtraLight.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-ExtraLight.ttf") format("ttf");
  font-weight: 200;
  font-style: normal;
}

// TitilliumWeb-ExtraLightItalic.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-ExtraLightItalic.ttf") format("ttf");
  font-weight: 200;
  font-style: italic;
}

// TitilliumWeb-Light.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Light.ttf") format("ttf");
  font-weight: 300;
  font-style: normal;
}

// TitilliumWeb-LightItalic.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-LightItalic.ttf") format("ttf");
  font-weight: 300;
  font-style: italic;
}

// TitilliumWeb-Regular.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Regular.ttf") format("ttf");
  font-weight: 400;
  font-style: normal;
}

// TitilliumWeb-Italic.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Italic.ttf") format("ttf");
  font-weight: 400;
  font-style: italic;
}

// TitilliumWeb-SemiBold.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-SemiBold.ttf") format("ttf");
  font-weight: 600;
  font-style: normal;
}

// TitilliumWeb-SemiBoldItalic.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-SemiBoldItalic.ttf") format("ttf");
  font-weight: 600;
  font-style: italic;
}

// TitilliumWeb-Bold.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Bold.ttf") format("ttf");
  font-weight: 700;
  font-style: normal;
}

// TitilliumWeb-BoldItalic.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-BoldItalic.ttf") format("ttf");
  font-weight: 700;
  font-style: italic;
}

// TitilliumWeb-Black.ttf:
@font-face {
  font-family: 'Titillium Web', sans-serif;
  src: url("../fonts/TitilliumWeb-Black.ttf") format("ttf");
  font-weight: 900;
  font-style: normal;
}
