/**
* MAIN STYLESHEET
*/

// import libraries:
@import '../../../node_modules/include-media/dist/_include-media.scss'; // @include-media media queries
@import '../../../node_modules/normalize.css/normalize.css';            // normalize css
@import '../../../node_modules/aos/src/sass/aos.scss';                  // settings for aos library (actual library is imported via node)

// import dependencies and styles:
@import 'partials/_breakpoints';  // breakpoint definitions
@import 'partials/_fonts';        // @font-face fonts
@import 'partials/_variables';    // variable definitions
@import 'partials/_global';       // global styles for *, body, html, type, img, etc.
@import 'partials/_header';       // header styles
@import 'partials/_content';      // page content styles
@import 'partials/_footer';       // footer styles
@import 'partials/_misc';         // miscellaneous styles

@import 'components/_gallery';    // gallery styles
