/**
* Breakpoints
*/

// breakpoint definitions for use with include-media.scss library:
$breakpoints: (
  'phone-small': 320px,
  'phone-large': 480px,
  'tablet':      768px,
  'laptop':      992px,
  'desktop':     1200px,
  '4k':          1920px
);


// usage examples:
/*

@include media(">=phone-small") {

}

@include media(">=phone-large") {

}

@include media(">=tablet") {

}

@include media(">=laptop") {

}

@include media(">=desktop") {

}

@include media(">=4k") {

}

*/
