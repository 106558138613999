
.gallery {
  width: 100%;
  max-width: 100%;
  min-height: 100%; //auto
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto 60px auto;
  //align-content: center;
}
  .galleryDisplay {
    //width: 100%;
    height: 100%;
    //margin: 0 auto;
    //padding: 20px;
    //display: inline-block;

    //margin-bottom: -4px;
    position: relative;
    touch-action: none;

    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    //align-items: center;
    //align-self: center;
    display: grid;
    align-content: center;
  }
    .galleryDisplayPort {
      width: auto;
      margin: 0 auto;
      //align-self: center;
      //border-top: 4px solid darken($baseColor, 10%);
      //border-right: 4px solid lighten($baseColor, 5%);
      //border-bottom: 4px solid lighten($baseColor, 10%);
      //border-left: 4px solid lighten($baseColor, 5%);

      transition: background-color 2.5s ease;
      background-color: #ffffff;

    }
      img.galleryDisplayImage {
        width: auto;
        max-width: 100%;
        height: auto;
        display: block;
        //margin: 0 auto;
        outline: 4px solid #000; //rgba(0, 0, 0, 0.5);
        outline-offset: -4px;
        //position: absolute;
        //top: 50%;
        //left: 50%;
        //-webkit-transform: translate(-50%, -50%);
        //transform: translate(-50%, -50%);

        mix-blend-mode: multiply;
        opacity: 100%;


        @include media(">=tablet") {
          outline: 8px solid #000; //rgba(0, 0, 0, 0.5);
          outline-offset: -8px;
        }

      }

      .galleryDisplayMagnifier {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        outline: 4px solid rgba(255, 255, 255, 0.1); //rgba(255, 255, 255, 0.3);
        outline-offset: -4px;
        /*
        border-top: 1px solid rgba(230, 230, 230, 0.5);
        border-right: 1px solid rgba(170, 170, 170, 0.5);
        border-bottom: 1px solid rgba(100, 100, 100, 0.5);
        border-left: 1px solid rgba(170, 170, 170, 0.5);
        border-radius: 1px;
        */
        border-radius: 10px;

        transition: background-color 2.5s ease;
        background-color: #ffffff;
        background-blend-mode: multiply;
        opacity: 100%;
      }

  .galleryInfo {
    width: 100%;
    max-width: 100%;
    height: auto;//$infoHeight;
    padding: 12px;
    //line-height: 40px;$infoHeight;
    font-size: 1rem;
    color: #aaa;
    //position: fixed;
    //bottom: 115px;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
    text-align: center;
  }
    .galleryInfoTitle {
      font-style: italic;
      font-weight: bold;
    }

    .galleryInfoYear {

    }

    .galleryInfoMaterials {

    }

    .galleryInfoSize {

    }

  .galleryControl {
    width: auto;
    max-width: 100%;
    height: 60px;
    margin: 0 auto;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
    //position: fixed;
    //bottom: 55px;
    overflow: hidden;
    //overflow-y: visible;
    position: relative;
    z-index: 0;
    grid-column: 1 / span 1;
    grid-row: 3 / span 1;

    display: flex;
  }
    .galleryControlButton {
      width: 90px;
      height: 60px;
      display: block;
      position: absolute;
      top: 0;
      //color: orange;
      cursor: pointer;
    }

    .galleryControlPrevious {
      left: 0;
      z-index: 99;
      background-image: linear-gradient(to left, rgba(34, 34, 34, 0.0), rgba(34, 34, 34, 1.0)), linear-gradient(to left, rgba(34, 34, 34, 1.0), rgba(34, 34, 34, 1.0)), linear-gradient(to left, rgba(34, 34, 34, 1.0), rgba(34, 34, 34, 1.0));
      background-size: 33.3333333% 100%, 33.3333333% 100%, 33.3333333% 100%;
      background-position: right, center, left;
      background-repeat: no-repeat;
    }
      .galleryControlPreviousArrow {
        width: 0;
        height: 0;
        margin: 0 30px;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-right: 30px solid #aaa;
        transition: border-right 300ms;

        &:hover {
          border-right: 30px solid #ddd;
        }
      }

    .galleryControlNext {
      right: 0;
      z-index: 99;
      background-image: linear-gradient(to right, rgba(34, 34, 34, 0.0), rgba(34, 34, 34, 1.0)), linear-gradient(to right, rgba(34, 34, 34, 1.0), rgba(34, 34, 34, 1.0)), linear-gradient(to right, rgba(34, 34, 34, 1.0), rgba(34, 34, 34, 1.0));
      background-size: 33.3333333% 100%, 33.3333333% 100%, 33.3333333% 100%;
      background-position: left, center, right;
      background-repeat: no-repeat;
    }
      .galleryControlNextArrow {
        width: 0;
        height: 0;
        margin: 0 30px;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 30px solid #aaa;
        transition: border-left 300ms;

        &:hover {
          border-left: 30px solid #ddd;
        }
      }

    .galleryControlSubjects {
      width: 100%;
      height: 60px;
      margin: 0 auto;
      padding: 0 75px;
      box-sizing: border-box;
      position: relative;
      text-align: center;
      z-index: 1;

      ul.galleryControlSubjectList {
        width: auto;
        height: 60px;
        margin: 0;
        padding: 0;
        display: inline-block;
        list-style: none;
        text-align: center;
        transition: 0.3s ease-in-out;

        li.galleryControlSubjectItem {
          width: 60px;
          height: 60px;
          margin: 0 5px;
          padding: 0;
          display: inline-block;
          list-style: none;
          background-color: rgba(255, 255, 255, 1.0);
          border-radius: 50%;
          //border: 1px solid rgba(0, 0, 0, 1.0);
          //outline: 6px solid #222;
          //outline-offset: -3px;

          &:hover {
            //outline: 3px solid #222;
            //outline-offset: -2px;
            //background-color: rgba(255, 134, 0, 1.0);
          }

          img.galleryControlSubjectImage {
            width: 60px;
            height: 60px;
            object-fit: cover;
            object-position: center;
            display: block;
            cursor: pointer;
            border-radius: 50%;
            outline: 6px solid $baseColor;
            outline-offset: -3px;
            transition: opacity 400ms;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
          .galleryControlSubjectImageActive {
            opacity: 0.4;
            outline: 6px solid #fff;
            outline-offset: -3px;
            //filter: alpha(opacity=20); /* For IE8 and earlier */
            //-moz-box-shadow:    0px 0px 5px 5px #fff;
            //-webkit-box-shadow: 0px 0px 5px 5px #fff;
            //box-shadow:         0px 0px 5px 5px #fff;

            &:hover {
              opacity: 0.4 !important;
            }
          }

  .galleryLighting {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    z-index: 0;
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
  }
    .galleryLightingList {
      width: auto;
      height: auto;
      margin: 0;
      padding: 10px 0;
      //display: inline-block;
      list-style: none;
      text-align: center;
      transition: 0.3s ease-in-out;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

      .galleryLightingItem {
        width: 40px;
        height: 40px;
        margin: 0 5px;
        padding: 0;
        display: inline-block;
        box-sizing: content-box;
        list-style: none;
        //background-color: rgba(255, 255, 255, 1.0);
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.5);
        //outline: 6px solid #222;
        //outline-offset: -3px;
        cursor: pointer;
        transition: box-shadow 0.5s ease, color 0.5s ease;
        box-shadow: inset 0px 0px 30px 12px rgba(40, 40, 40, 1.0);
//        outline: 2px solid #191919;
//        outline-offset: -1px;
        align-content: center;
        //justify-content: center;
        line-height: 40px;

        mix-blend-mode: soft-light;
        opacity: 100%;

        &:hover {
          box-shadow: inset 0px 0px 30px 12px rgba(40, 40, 40, 0.8);
          //outline: 4px solid #191919;
        }

        &:nth-child(5n) {
          page-break-after: always; /* CSS 2.1 syntax */
          break-after: always; /* New syntax */

          @include media(">=phone-large") {
            page-break-after: auto; /* CSS 2.1 syntax */
            break-after: auto; /* New syntax */
          }
        }

        &:nth-child(6n), &:nth-child(7n), &:nth-child(8n), &:nth-child(9n), &:nth-child(10n) {
          margin-top: 10px;

          @include media(">=phone-large") {
            margin-top: 0;
          }
        }
      }

      .galleryLightingColor {

        &:hover {

        }
      }

      .galleryLightingColor1, .galleryLightingColor2, .galleryLightingColor3, .galleryLightingColor4, .galleryLightingColor5, .galleryLightingColor6, .galleryLightingColor7 {
        &:after {
          content: "";
          width: 10px;
          height: 0px;
          border-bottom: 2px solid #333;
          border-top: 2px solid #111;
          display: block;
          position: relative;
          left: 41px;
          bottom: 22px;
        }
      }

      .galleryLightingColor1 {
        background-color: #ffffff;
        //box-shadow: 0px 0px 4px 4px #fff;
      }

      .galleryLightingColor2 {
        background-color: #ff0000;
      }

      .galleryLightingColor3 {
        background-color: #00ff00
      }

      .galleryLightingColor4 {
        background-color: #0000ff;
      }

      .galleryLightingColor5 {
        background-color: #ff00ff;
      }

      .galleryLightingColor6 {
        background-color: #ffff00;
      }

      .galleryLightingColor7 {
        background-color: #00ffff;
      }

      .galleryLightingOptionCycle {
        background-image: conic-gradient(red, yellow, green, cyan, blue, magenta);
        position: relative;
        color: #fff;

        i {
          position: relative;
          left: 2px;
        }
      }

      .galleryLightingOptionUV {
        background-color: #2200aa;
        color: #fff;
        margin-left: 10px;
      }

      .galleryLightingColorActive {
        box-shadow: unset;
        color: #000;
        //outline: 2px solid #222;

        &:hover {
          box-shadow: unset;
        }
      }

      .galleryLightingOptionCycleActive {
        box-shadow: unset;
        color: #000;

        i {
          position: relative;
          left: 0px;
        }
      }

      .galleryLightingOptionUVActive {
        box-shadow: unset;
        color: #ff00ff;
      }

      /*
      @keyframes galleryLightingAnimatedBg {
        0% { background-color: red; }
        16% { background-color: yellow; }
        33% { background-color: green;}
        50% { background-color: cyan; }
        66% { background-color: blue; }
        83% { background-color: magenta; }
        100% { background-color: red; }
      }

      .galleryLightingAnimated {
        animation: galleryLightingAnimatedBg 10s infinite;
      }
      */
